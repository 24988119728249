import axios from './index'
import FormValidationError from '@/errors/FormValidationError'
import AppError from '@/errors/AppError'

export async function findPeople(page = 1) {
  const url = `/accounts/people/?page=${page}`
  const response = await axios.get(url)
  return response.data.results
}

/*
 * This function searches people by some query. At the time of writing of this
 * function, the search is performed by either mail or person name. It will
 * provide 20 results and will *NOT* make any effort to retrieve more than
 * that amount of results. If you need such functionality, please create a
 * wrapper around this function
 */
export async function searchPeople(query) {
  let searchParams = ''
  if (query) {
    searchParams = `?search=${query}`
  }
  const url = `/api/people${searchParams}`
  const response = await axios.get(url)
  return response.data
}

export async function fetchPeople() {
  const response = await axios.get(`api/people`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function fetchPeopleWithPermissions() {
  const response = await axios.get(`api/people/permissions`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function findMemberDivisions({ userId }) {
  const requestUrl = `api/people/${userId}/divisions`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function findMemberProjects({ userId }) {
  const requestUrl = `api/people/${userId}/projects`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function findMember({ userId }) {
  const requestUrl = `api/people/${userId}/semi-public`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function findPublicMember({ userId }) {
  const requestUrl = `api/people/${userId}/public`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}
export async function findDependants({ id, url }) {
  if (typeof id === 'undefined' && typeof url === 'undefined') {
    return null
  }

  let requestUrl = url
  if (!requestUrl) {
    requestUrl = `/accounts/people/${id}/dependants/`
  }

  const response = await axios.get(requestUrl)

  return response.data
}

export async function patchProfile(data) {
  const response = await axios.patch(`api/people/profile`, data)
  if (response.status === 200) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function fetchCountries() {
  const response = await axios.get(`api/countries`)
  if (response.status === 200) {
    return response.data
  }
}

export async function uploadAvatar(file) {
  const data = new FormData()
  data.append('file', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const response = await axios.post(`api/people/profile/avatar`, data, config)
  if (response.status == 200) {
    return response.data
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function requestPasswordRecovery(data) {
  const response = await axios.post(`api/people/request-password-reset`, data)
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function passwordRecovery({ token, data }) {
  const response = await axios.post(`api/people/reset-password/${token}`, data)
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 404 || response.status === 410) {
    throw new AppError(response.data.detail)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function assignManagePeoplePermission({ userId }) {
  const response = await axios.patch(`api/people/${userId}/grant-global-permission-power`)
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function assignSeeSensitiveInfoPermission({ userId }) {
  const response = await axios.patch(`api/people/${userId}/grant-global-view`)
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function deleteManagePeoplePermission({ userId }) {
  const response = await axios.delete(`api/people/${userId}/grant-global-permission-power`)
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function deleteSeeSensitiveInfoPermission({ userId }) {
  const response = await axios.delete(`api/people/${userId}/grant-global-view`)
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export async function updateAdminDivisionPermissions({ userId, permissionList }) {
  const response = await axios.patch(
    `api/people/${userId}/edit-division-permissions`,
    permissionList
  )
  if (response.status == 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else {
    throw new Error('Unexpected status code returned')
  }
}

export const findManagedDivisions = async () => {
  const response = await axios.get(`api/people/managed-divisions`)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const deactivateUser = async ({ userId }) => {
  const response = await axios.patch(`api/people/${userId}/deactivate`)
  if (response.status === 204) {
    return
  }
  throw new Error('Unexpected status code returned')
}

export const findLinkedRolesCount = async ({ userId }) => {
  const requestUrl = `api/people/${userId}/linked-roles-count`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}
