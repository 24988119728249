<template>
  <div class="card" style="padding: 20px">
    <div class="card-content">
      <h1 class="title is-4">{{ division.name }}</h1>
      <img class="imfd-divider is-block" src="@/assets/svgs/divisor.svg" alt="" />
      <h2 class="subtitle is-5">
        {{ $t('membersModal.members', { n: division.active_members.length }) }}
      </h2>

      <table class="table is-fullwidth is-narrow">
        <thead class="table-head-members">
          <tr>
            <th>{{ $t('membersTable.name') }}</th>
            <th>{{ $t('membersTable.rol') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in division.active_members" :key="member.id">
            <td class="table-row">
              <router-link
                class="member-link"
                :to="{ name: 'memberProfile', params: { id: member.id } }"
              >
                <figure class="image avatar user-img is-24x24">
                  <img class="is-rounded" :src="`${backendUrl}${member.avatar}`" />
                </figure>
                {{ `${member.first_name} ${member.last_name}` }}
              </router-link>
            </td>
            <td>
              {{ member.role_type || member.membership_type }}
            </td>
            <div v-if="person.manage_division_information.includes(parseInt($route.params.id))">
              <td>
                <div class="rounded-badge">
                  <a class="icon-button-style">
                    <span
                      @click="
                        $router.push({
                          name: 'editMembership',
                          params: { divisionId: division.id, memberId: member.id }
                        })
                      "
                    >
                      <b-icon icon="pencil-outline" />
                    </span>
                  </a>
                </div>
              </td>
              <td>
                <div class="rounded-badge">
                  <a class="icon-button-style">
                    <span @click="deleteAlert(member.id)">
                      <b-icon icon="trash-can-outline" />
                    </span>
                  </a>
                </div>
              </td>
            </div>
          </tr>
        </tbody>
      </table>
      <footer>
        <b-button
          :label="$t('membersModal.closeButton')"
          @click="$emit('close')"
          class="close-button"
        />
      </footer>
    </div>
  </div>
</template>

<style scoped>
.close-button {
  background-color: rgb(228, 228, 231);
  width: 100%;
}
</style>

<script>
import { deleteMemberRole } from '@/requests/divisions'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  props: {
    division: {
      type: Object,
      require: true
    }
  },
  methods: {
    deleteAlert(memberId) {
      this.$buefy.dialog.confirm({
        message: this.division.is_project
          ? this.$t('membersModal.dialogDeleteConfirmProject')
          : this.$t('membersModal.dialogDeleteConfirmDivision'),
        confirmText: this.$t('membersModal.confirm'),
        cancelText: this.$t('membersModal.cancel'),
        onConfirm: async () => {
          await deleteMemberRole({ divisionId: this.division.id, memberId })
          this.$emit('deleteMember')
        },
        onCancel: () => {
          this.val = []
        }
      })
    }
  },
  computed: {
    ...mapState(['person']),
    backendUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return url.replace(/\/$/, '')
    }
  }
}
</script>
